import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { login } from "../api.js";
import LanguageDialog from "../components/LanguageDialog";
import { withTranslation } from "react-i18next";
import "../style.css";

class Login extends Component {
  state = {
    username: "",
    password: "",
    errorMsg: null,
    lang: null,
    langs: {
      uk: "Українська",
      en: "English",
      ru: "Русский",
    },
    languageDialogOpen: false,
  };

  componentDidMount() {
    this.extractCurrentLanguage();
  }

  handleLanguageButton() {
    this.setState({ languageDialogOpen: true });
  }

  handleLanguageSelectorClose() {
    this.setState({ languageDialogOpen: false });
    this.extractCurrentLanguage();
  }

  extractCurrentLanguage() {
    const selectedLang = localStorage.getItem("language");
    const lang =
      selectedLang == null
        ? window.navigator.language.substring(0, 2)
        : selectedLang;
    this.setState({ lang: lang });
  }

  doLogin() {
    const { t } = this.props;

    login(this.state.username, this.state.password, (r) => {
      const jwt = require("jsonwebtoken");
      const err = r.error;
      if (err != null) {
        if (err === "Username or password is wrong") {
          this.setState({ errorMsg: t("wrong_credentials") });
          return;
        }
        this.setState({ errorMsg: err });
        return;
      }

      this.setState({ errorMsg: null });

      const payload = jwt.decode(r.accessToken, { complete: true }).payload;
      localStorage.setItem("id", payload.userId);
      localStorage.setItem("jwt", r.accessToken);
      localStorage.setItem("rjwt", r.refreshToken);
      window.location.replace("/");
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="outer">
          <div className="middle">
            <div className="form-fields">
              <div className="logo">
                Lifecapsule
                <div className="signature">
                  by{" "}
                  <a href="https://leskor.com" alt="Ruslan Lesko">
                    Ruslan Lesko
                  </a>
                </div>
              </div>
              <div>
                <TextField
                  label={t("username")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
              </div>
              <div>
                <TextField
                  label={t("password")}
                  type="password"
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div className="errorMsg">{this.state.errorMsg}</div>
              <div className="button">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fullWidth={true}
                  onClick={() => this.doLogin()}
                >
                  {t("login")}
                </Button>
              </div>
              <div className="button">
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  onClick={() => {
                    window.location.replace("/signup");
                  }}
                >
                  {t("signup")}
                </Button>
              </div>
              <div className="button">
                <Button
                  color="primary"
                  fullWidth={true}
                  onClick={() => {
                    window.location.replace("/forgot_password");
                  }}
                >
                  {t("forgot_password")}
                </Button>
              </div>
              <div className="button">
                <img
                  src={"/" + this.state.lang + ".png"}
                  alt="Flag"
                  style={{ marginTop: "2em", marginLeft: "6em" }}
                />
                <Button
                  onClick={() => this.handleLanguageButton()}
                  color="primary"
                >
                  {this.state.langs[this.state.lang]}
                </Button>
              </div>
              <LanguageDialog
                open={this.state.languageDialogOpen}
                handleClose={() => this.handleLanguageSelectorClose()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
