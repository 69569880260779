import { redirectToLogin } from "./utils.js";
import heic2any from "heic2any";

// const authBase = "http://localhost:2525";
// const coreBase = "http://localhost:8081";
// const domain = "localhost";
const authBase = "https://api.leskor.com/auth";
const coreBase = "https://api.leskor.com/core";
const domain = "leskor.com";

async function refreshToken(onSuccess) {
  const url = authBase + "/v1/token?type=refresh_token";
  const jwt = localStorage.getItem("rjwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status !== 200) {
        redirectToLogin();
      } else {
        r.json().then((j) => {
          localStorage.setItem("jwt", j.accessToken);
          onSuccess();
        });
      }
    })
    .catch((e) => console.log(e));
}

async function login(username, password, onResult) {
  const url = authBase + "/v1/token?type=user_credentials";
  const credentials = btoa(username + ":" + password);
  const options = {
    method: "POST",
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  };

  fetch(url, options)
    .then((r) => r.json())
    .then(onResult)
    .catch((e) => console.log(e));
}

async function signup(user, onResult) {
  const url = authBase + "/v1/users";
  const options = {
    method: "POST",
    body: JSON.stringify(user),
  };

  fetch(url, options)
    .then((r) => r.json())
    .then(onResult)
    .catch((e) => console.log(e));
}

function setCachedUserByIdValue(id, data) {
  if (id === undefined) {
    return;
  }
  const key = "cache:userbyid:" + id;
  const cachedObj = {
    timestamp: new Date().getTime(),
    data: data
  };
  localStorage.setItem(key, JSON.stringify(cachedObj));
}

function getCachedUserByIdValue(id) {
  const key = "cache:userbyid:" + id;
  const existing = localStorage.getItem(key);
  if (existing === null) {
    return null;
  }
  const cachedObj = JSON.parse(existing);
  const stamp = cachedObj.timestamp;
  const current = new Date().getTime();
  if (current - stamp > 15 * 60 * 1000) {
    return null;
  }
  return cachedObj.data;
}

async function getUserById(id, onResult, isRepeated = false) {
  const url = authBase + "/v1/userinfo/byid/" + id;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }

  const cached = getCachedUserByIdValue(id);
  if (cached !== null) {
    onResult(cached);
    return;
  }

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => getUserById(id, onResult, true));
          return;
        }
        redirectToLogin();
      } else {
        r.json().then(data => {
          setCachedUserByIdValue(id, data);
          onResult(data);
        });
      }
    })
    .catch((e) => console.log(e));
}

async function getUserByUsername(username, onResult, isRepeated = false) {
  const url = authBase + "/v1/userinfo/byusername/" + username;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => getUserByUsername(username, onResult, true));
          return;
        }
        redirectToLogin();
      } else {
        r.json().then(onResult);
      }
    })
    .catch((e) => console.log(e));
}

async function updateUser(userId, body, onSuccess, isRepeated = false) {
  const url = authBase + "/v1/users/" + userId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }

  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(body),
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => updateUser(userId, body, onSuccess, true));
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function fetchAlbums(userId, onResult, isRepeated = false) {
  const url = coreBase + "/album/" + userId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => fetchAlbums(userId, onResult, true));
          return;
        }
        redirectToLogin();
      } else {
        r.json().then(onResult);
      }
    })
    .catch((e) => console.log(e));
}

async function sendCreateAlbum(userId, body, onSuccess, isRepeated = false) {
  const url = coreBase + "/album/" + userId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => sendCreateAlbum(userId, body, onSuccess, true));
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function removeAlbum(userId, albumId, onSuccess, isRepeated = false) {
  const url = coreBase + "/album/" + userId + "/" + albumId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => removeAlbum(userId, albumId, onSuccess, true));
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function shareAlbum(
  userId,
  albumId,
  idsToShare,
  onSuccess,
  isRepeated = false
) {
  const url = coreBase + "/album/" + userId + "/" + albumId + "/share";
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "POST",
    body: JSON.stringify({ sharedUsers: idsToShare }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() =>
            shareAlbum(userId, albumId, idsToShare, onSuccess, true)
          );
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function renameAlbum(
  userId,
  albumId,
  newTitle,
  onSuccess,
  isRepeated = false
) {
  const url = coreBase + "/album/" + userId + "/" + albumId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "PATCH",
    body: JSON.stringify({ name: newTitle }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() =>
            renameAlbum(userId, albumId, newTitle, onSuccess, true)
          );
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function sortAlbum(userId, albumId, isChronologicalOrder, onSuccess, isRepeated = false) {
  const url = coreBase + "/album/" + userId + "/" + albumId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "PATCH",
    body: JSON.stringify({ isChronologicalOrder: isChronologicalOrder }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() =>
            sortAlbum(userId, albumId, isChronologicalOrder, onSuccess, true)
          );
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function fetchAlbumContents(
  userId,
  albumId,
  onResult,
  isRepeated = false
) {
  const url = coreBase + "/album/" + userId + "/" + albumId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() =>
            fetchAlbumContents(userId, albumId, onResult, true)
          );
          return;
        }
        redirectToLogin();
      } else {
        r.json().then(onResult);
      }
    })
    .catch((e) => console.log(e));
}

async function uploadPicture(
  userId,
  albumId,
  data,
  onSuccess,
  onError,
  isRepeated = false
) {
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }

  if (data.type === "image/jpg" || data.type === "image/jpeg") {
    doUploadPicture(userId, albumId, data, onSuccess, onError, false);
    return;
  }

  const blob = new Blob([data]);

  heic2any({
    blob,
    toType: "image/jpeg",
    quality: 1,
  })
    .then((converted) => {
      doUploadPicture(userId, albumId, converted, onSuccess, onError, true);
    })
    .catch((e) => {
      if (e.code === 1) {
        doUploadPicture(userId, albumId, blob, onSuccess, onError, true);
      } else {
        console.log(e);
      }
    });
}

async function doUploadPicture(
  userId,
  albumId,
  data,
  onSuccess,
  onError,
  isHeic,
  isRepeated = false
) {
  const url = coreBase + "/pic/" + userId + "?albumId=" + albumId;
  const jwt = localStorage.getItem("jwt");

  const jpgFile = isHeic
    ? new File([data], "image.jpeg", { type: "image/jpeg" })
    : data;

  const options = {
    method: "POST",
    body: jpgFile,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() =>
            doUploadPicture(userId, albumId, data, onSuccess, isHeic, true)
          );
          return;
        }
        redirectToLogin();
      } else {
        if (r.status === 400) {
          r.json().then(onError);
        } else {
          onSuccess();
        }
      }
    })
    .catch((e) => console.log(e));
}

async function deletePicture(userId, pictureId, onSuccess, isRepeated = false) {
  const url = coreBase + "/pic/" + userId + "/" + pictureId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => deletePicture(userId, pictureId, onSuccess, true));
          return;
        }
        redirectToLogin();
      } else {
        r.json().then((body) => onSuccess());
      }
    })
    .catch((e) => console.log(e));
}

async function fetchImage(userId, pictureId, onResult, isRepeated = false) {
  const fullSize = window.innerWidth > 700 ? "?fullSize=true" : "";
  const url = coreBase + "/pic/" + userId + "/" + pictureId + fullSize;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => fetchImage(userId, pictureId, onResult, true));
          return;
        }
        redirectToLogin();
      } else {
        r.blob().then(onResult);
      }
    })
    .catch((e) => console.log(e));
}

async function rotateImage(userId, pictureId, onSuccess, isRepeated = false) {
  const url = coreBase + "/pic/" + userId + "/" + pictureId + "/rotate";
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => rotateImage(userId, pictureId, onSuccess, true));
          return;
        }
        redirectToLogin();
      } else {
        onSuccess();
      }
    })
    .catch((e) => console.log(e));
}

async function getStorage(userId, onResult, isRepeated = false) {
  const url = coreBase + "/storage/" + userId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => getStorage(userId, true));
          return;
        }
        redirectToLogin();
      } else {
        r.json().then(onResult)
      }
    })
    .catch((e) => console.log(e));
}

async function emailRecoveryCode(username, onResult) {
  const url = authBase + "/v1/password-recovery/email";

  const body = {
    username: username
  };

  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };

  fetch(url, options)
    .then(onResult)
    .catch((e) => console.log(e));
}

async function exchangeRecoveryCode(username, code, onResult) {
  const url = authBase + "/v1/password-recovery/exchange";

  const body = {
    username: username,
    code: code
  };

  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };

  fetch(url, options)
    .then(onResult)
    .catch((e) => console.log(e));
}

async function resetPassword(username, code, newPassword, onResult) {
  const url = authBase + "/v1/password-recovery/reset";

  const body = {
    username: username,
    code: code,
    password: newPassword
  };

  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };

  fetch(url, options)
    .then(onResult)
    .catch((e) => console.log(e));
}

async function deleteAccountData(userId, onSuccess, onFailure, isRepeated = false) {
  const url = coreBase + "/album/" + userId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => deleteAccountData(userId, onSuccess, onFailure, true));
          return;
        }
        redirectToLogin();
      } else if (r.status === 200) {
        onSuccess();
      } else {
        onFailure();
      }
    })
    .catch((e) => console.log(e));
}

async function deleteAccount(userId, onSuccess, onFailure, isRepeated = false) {
  const url = authBase + "/v1/users/" + userId;
  const jwt = localStorage.getItem("jwt");
  if (jwt === null) {
    redirectToLogin();
    return;
  }
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  fetch(url, options)
    .then((r) => {
      if (r.status === 401) {
        if (!isRepeated) {
          refreshToken(() => deleteAccount(userId, onSuccess, onFailure, true));
          return;
        }
        redirectToLogin();
      } else if (r.status === 200) {
        onSuccess();
      } else {
        onFailure();
      }
    })
    .catch((e) => console.log(e));
}

function createDownloadUrl(uid, pid) {
  const token = localStorage.getItem("jwt");
  const d = new Date();
  d.setTime(d.getTime() + (30*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = "token=" + token + ";" + expires + ";path=/;domain=." + domain;
  return coreBase + "/pic/" + uid + "/" + pid;
}

function createAlbumDownloadUrl(uid, aid) {
  const token = localStorage.getItem("jwt");
  const d = new Date();
  d.setTime(d.getTime() + (2*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = "token=" + token + ";" + expires + ";path=/;domain=." + domain;
  return coreBase + "/album/" + uid + "/" + aid + "/download";
}

export {
  login,
  signup,
  getUserById,
  getUserByUsername,
  fetchAlbums,
  sendCreateAlbum,
  removeAlbum,
  shareAlbum,
  renameAlbum,
  sortAlbum,
  fetchAlbumContents,
  uploadPicture,
  deletePicture,
  fetchImage,
  rotateImage,
  getStorage,
  updateUser,
  emailRecoveryCode,
  exchangeRecoveryCode,
  resetPassword,
  createDownloadUrl,
  createAlbumDownloadUrl,
  deleteAccountData,
  deleteAccount
};
