import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { emailRecoveryCode } from "../api.js";
import { withTranslation } from "react-i18next";

class ForgotPassword extends Component {
	state = {
		username: "",
		errorMsg: ""
	}

	emailCode() {
		const { t } = this.props;
		const uname = this.state.username;

		emailRecoveryCode(uname, (r) => {
			if (r.status === 200) {
				localStorage.setItem("username", uname);
				this.setState({errorMsg: ""});
				window.location.replace("/recovery_code");
				return;
			}

			if (r.status >= 500) {
				this.setState({errorMsg: t("src_server_err")});
				return;
			}

			this.setState({errorMsg: t("src_client_err")});
		});
	}

	render() {
		const { t } = this.props;

		return (
			<div>
				<div className="outer">
					<div className="middle">
						<div className="form-fields">
							<div className="logo">
								Lifecapsule
                <div className="signature">
									by{" "}
									<a href="https://leskor.com" alt="Ruslan Lesko">Ruslan Lesko</a>
								</div>
							</div>

							<div>
                <TextField
                  label={t("username")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
              </div>

							<div className="errorMsg">{this.state.errorMsg}</div>

							<div className="button">
								<Button
									variant="contained"
									color="primary"
									fullWidth={true}
									style={{ color: "white" }}
									onClick={() => this.emailCode()}
								>
									{t("send_recovery_code")}
								</Button>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(ForgotPassword);