import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

class NewAlbumDialog extends Component {
  state = {
    title: "",
  };

  render() {
    const { t } = this.props;

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose}>
        <DialogTitle>{t("create_album")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("album_name")}
            margin="normal"
            variant="outlined"
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleCreate(this.state.title)}
            color="primary"
            style={{ color: "white" }}
            variant="contained"
          >
            {t("create")}
          </Button>
          <Button onClick={this.props.handleClose} color="primary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(NewAlbumDialog);
