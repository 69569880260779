import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LanguageDialog from "../components/LanguageDialog";
import { signup } from "../api.js";
import { withTranslation } from "react-i18next";
import "../style.css";

class Signup extends Component {
  state = {
    Username: "",
    Password: "",
    FirstName: "",
    LastName: "",
    Email: "",
    inviteCode: "",
    errorMsg: null,
    lang: null,
    langs: {
      uk: "Українська",
      en: "English",
      ru: "Русский",
    },
    languageDialogOpen: false,
  };

  componentDidMount() {
    this.extractCurrentLanguage();
  }

  handleLanguageButton() {
    this.setState({ languageDialogOpen: true });
  }

  handleLanguageSelectorClose() {
    this.setState({ languageDialogOpen: false });
    this.extractCurrentLanguage();
  }

  extractCurrentLanguage() {
    const selectedLang = localStorage.getItem("language");
    const lang =
      selectedLang == null
        ? window.navigator.language.substring(0, 2)
        : selectedLang;
    this.setState({ lang: lang });
  }

  doSignUp() {
    const user = {
      username: this.state.Username,
      password: this.state.Password,
      firstName: this.state.FirstName,
      lastName: this.state.LastName,
      email: this.state.Email,
      inviteCode: this.state.inviteCode
    };

    const { t } = this.props;

    signup(user, (r) => {
      const err = r.error;
      if (err != null) {
        if (err === "Username already exists") {
          this.setState({ errorMsg: t("username_already_exists") });
          return;
        }
        this.setState({ errorMsg: err });
        return;
      }

      this.setState({ errorMsg: null });

      window.location.replace("/login");
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="outer">
          <div className="middle">
            <div className="form-fields">
              <div className="logo">
                Lifecapsule
                <div className="signature">
                  by{" "}
                  <a href="https://leskor.com" alt="Ruslan Lesko">
                    Ruslan Lesko
                  </a>
                </div>
              </div>
              <div>
                <TextField
                  label={t("invite_code")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ inviteCode: e.target.value })}
                />
              </div>
              <div>
                <TextField
                  label={t("username")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ Username: e.target.value })}
                />
              </div>
              <div>
                <TextField
                  label={t("password")}
                  margin="normal"
                  variant="outlined"
                  type="password"
                  fullWidth={true}
                  onChange={(e) => this.setState({ Password: e.target.value })}
                />
              </div>
              <div>
                <TextField
                  label={t("first_name")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ FirstName: e.target.value })}
                />
              </div>
              <div>
                <TextField
                  label={t("last_name")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ LastName: e.target.value })}
                />
              </div>
              <div>
                <TextField
                  label={t("email")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ Email: e.target.value })}
                />
              </div>
              <div className="errorMsg">{this.state.errorMsg}</div>
              <div className="button">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fullWidth={true}
                  onClick={() => this.doSignUp()}
                >
                  {t("signup")}
                </Button>
              </div>
              <div className="button">
                <img
                  src={"/" + this.state.lang + ".png"}
                  alt="Flag"
                  style={{ marginTop: "2em", marginLeft: "6em" }}
                />
                <Button
                  onClick={() => this.handleLanguageButton()}
                  color="primary"
                >
                  {this.state.langs[this.state.lang]}
                </Button>
              </div>
              <LanguageDialog
                open={this.state.languageDialogOpen}
                handleClose={() => this.handleLanguageSelectorClose()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Signup);
