import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { exchangeRecoveryCode } from "../api.js";
import { withTranslation } from "react-i18next";

class RecoveryCode extends Component {
	state = {
		code: "",
		errorMsg: ""
	}

	exchangeCode() {
		const { t } = this.props;
		const uname = localStorage.getItem("username");
		const c = this.state.code;

		exchangeRecoveryCode(uname, c, (r) => {
			if (r.status === 200) {
				r.json().then((j) => {
					localStorage.setItem("code", j.code);
					this.setState({errorMsg: ""});
					window.location.replace("/password_reset");
				});
				return;
			}

			if (r.status >= 500) {
				this.setState({errorMsg: t("src_server_err")});
				return;
			}

			this.setState({errorMsg: t("rc_client_err")});
		});
	}

	render() {
		const { t } = this.props;

		return (
			<div>
				<div className="outer">
					<div className="middle">
						<div className="form-fields">
							<div className="logo">
								Lifecapsule
                <div className="signature">
									by{" "}
									<a href="https://leskor.com" alt="Ruslan Lesko">Ruslan Lesko</a>
								</div>
							</div>

							<div>
                <TextField
                  label={t("code")}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => this.setState({ code: e.target.value })}
                />
              </div>

							<div className="errorMsg">{this.state.errorMsg}</div>

							<div className="button">
								<Button
									variant="contained"
									color="primary"
									fullWidth={true}
									style={{ color: "white" }}
									onClick={() => this.exchangeCode()}
								>
									{t("submit")}
								</Button>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(RecoveryCode);