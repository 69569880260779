import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import "../style.css";
import { withTranslation } from "react-i18next";
import { deleteAccountData, deleteAccount } from "../api.js";

class AccountDeletionDialog extends Component {
  state = {
    errorMsg: "",
  }

  deleteDataAndAccount() {
    const { t } = this.props;
    const userId = Number(localStorage.getItem("id"));

    deleteAccountData(
      userId, 
      () => {
        deleteAccount(
          userId, 
          () => {
            this.props.handleClose();
            localStorage.clear();
            window.location.replace("/login");
          },
          () => {
            this.setState({ errorMsg: t("delete_account_error") });
          }
        )
      },
      () => {
        this.setState({errorMsg: t("delete_account_data_error")});
      }
    )
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose}>
        <DialogTitle>{t("confirm_delete_account")}</DialogTitle>
        <DialogContent>
          {t("confirm_delete_account_comment")}
          <div className="errorMsg">
            {this.state.errorMsg}
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() => this.deleteDataAndAccount()}
          >
            {t("delete")}
          </Button>
          <Button onClick={this.props.handleClose} color="primary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(AccountDeletionDialog);
