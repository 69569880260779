import React, { Component } from "react";
import "../style.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LanguageDialog from "../components/LanguageDialog";
import AccountDeletionDialog from "../components/AccountDeletionDialog";
import AccountSettingsDialog from "../components/AccountSettingsDialog";
import { withTranslation } from "react-i18next";

class Header extends Component {
  state = {
    isMobile: false,
    isMenuOpen: false,
    anchorEl: null,
    languageDialogOpen: false,
    accountSettingsDialogOpen: false,
    accountDeletionOpen: false,
  };

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth < 700 });
  }

  getDesktopIconStyle() {
    return {
      width: "1.2em",
      color: "white",
      paddingBottom: "0em",
      marginTop: "-0.6em",
    };
  }

  getMobileIconStyle() {
    return {
      width: "0.8em",
      color: "white",
      paddingBottom: "0em",
      marginTop: "-0.4em",
    };
  }

  handleMenuClick(e) {
    this.setState({
      isMenuOpen: true,
      anchorEl: e.currentTarget,
    });
  }

  handleMenuClose() {
    this.setState({ isMenuOpen: false });
  }

  handleLangChangeItemClick() {
    this.setState({ isMenuOpen: false, languageDialogOpen: true });
  }

  handleAccountSettingsItemClick() {
    this.setState({ isMenuOpen: false, accountSettingsDialogOpen: true });
  }

  handleAccountDeletionItemClick() {
    this.setState({ isMenuOpen: false, accountDeletionOpen: true });
  }

  handleLogout() {
    this.setState( { isMenuOpen: false });
    localStorage.clear();
    window.location.href = "/login";
  }

  render() {
    const { t } = this.props;

    return (
      <div className="header">
        <div className="headerUser">
          {this.props.name}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            style={
              this.state.isMobile
                ? this.getMobileIconStyle()
                : this.getDesktopIconStyle()
            }
            onClick={(e) => this.handleMenuClick(e)}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </div>
        <div className="headerLogo">
          <a href="/">Lifecapsule</a>
          <div className="signature">
            by{" "}
            <a href="https://leskor.com" alt="Ruslan Lesko">
              Ruslan Lesko
            </a>
          </div>
        </div>
        {this.props.albumTitle === null ? (
          <div></div>
        ) : (
          <div className="headerAlbum">{this.props.albumTitle}</div>
        )}
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={() => this.handleMenuClose()}
          PaperProps={{
            style: {
              width: "20ch",
              marginTop: "2.8em",
              marginLeft: "1em",
            },
          }}
        >
          <MenuItem key={0} onClick={() => this.handleLangChangeItemClick()}>
            {t("change_language")}
          </MenuItem>
          <MenuItem key={1} onClick={() => this.handleAccountSettingsItemClick()}>
            {t("account_settings")}
          </MenuItem>
          <MenuItem key={2} onClick={() => this.handleLogout()}>
            {t("logout")}
          </MenuItem>
          <MenuItem key={3} onClick={() => this.handleAccountDeletionItemClick()}>
            {t("delete_account")}
          </MenuItem>
        </Menu>
        <LanguageDialog
          open={this.state.languageDialogOpen}
          handleClose={() => this.setState({ languageDialogOpen: false })}
        />
        <AccountDeletionDialog
          open={this.state.accountDeletionOpen}
          handleClose={() => this.setState({ accountDeletionOpen: false })}
        />
        <AccountSettingsDialog
          open={this.state.accountSettingsDialogOpen}
          userInfo={this.props.userInfo}
          onUpdate={() => this.props.onUpdate()}
          handleClose={() => this.setState({ accountSettingsDialogOpen: false })}
        />
      </div>
    );
  }
}

export default withTranslation()(Header);
