import React, { Component } from "react";
import { Dialog, LinearProgress } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import "../style.css";
import { withTranslation } from "react-i18next";
import { getStorage, updateUser } from "../api.js";

class AccountSettingsDialog extends Component {
  state = {
    userId: -1,
    consumtion: 0,
    limit: 0,
    progress: 0,
    editModeDisabled: true,
    firstName: "",
    lastName: "",
    email: "",
  };

  componentDidMount() {
    const userId = Number(localStorage.getItem("id"));
    this.setState({userId: userId});
    getStorage(userId, (r) => {
      this.setState({
        consumtion: r.size,
        limit: r.limit,
      });
      if (r.limit > 0) {
        const newProgress = r.size * 100 / r.limit;
        this.setState({progress: newProgress});
      }
    });
  }

  formatSize(raw) {
    var result = raw
    var unit = "bytes"
    if (raw >= 1024 * 1024 * 1024) {
      result = raw / (1024 * 1024 * 1024);
      unit = "Gb";
    } else if (raw >= 1024 * 1024) {
      result = raw / (1024 * 1024);
      unit = "Mb"
    } else if (raw >= 1024) {
      result = raw / 1024;
      unit = "Kb"
    }
    return result.toFixed(2) + " " + unit
  }

  saveChanges(onSave) {
    console.log("Saving changes");
    var body = {
      id: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email
    };
    updateUser(this.state.userId, body, () => onSave());
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog 
        open={this.props.open} 
        onClose={() => {
          this.setState({editModeDisabled: true});
          this.props.handleClose();
        }}
      >
        <DialogTitle>{t("account_settings")}</DialogTitle>
        <DialogContent>
          <div>
            <TextField 
              label={t("username")}
              disabled
              margin="normal"
              fullWidth
              defaultValue={this.props.userInfo.username}
            />
          </div>
          <div>
            <TextField 
              label={t("first_name")}
              disabled={this.state.editModeDisabled}
              margin="normal"
              fullWidth
              defaultValue={this.props.userInfo.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
          </div>
          <div>
            <TextField 
              label={t("last_name")}
              disabled={this.state.editModeDisabled}
              margin="normal"
              fullWidth
              defaultValue={this.props.userInfo.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
          </div>
          <div>
            <TextField 
              label={t("email")}
              disabled={this.state.editModeDisabled}
              margin="normal"
              type="email"
              fullWidth
              defaultValue={this.props.userInfo.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <br />
          <h3>{t("storage_usage")}</h3>
          <p>{this.formatSize(this.state.consumtion)} {t("from")} {this.formatSize(this.state.limit)}</p>
          <LinearProgress
            variant="determinate"
            value={this.state.progress}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() => {
              if (this.state.editModeDisabled) {
                this.setState({editModeDisabled: false});
                return;
              }
              this.saveChanges(() => this.props.onUpdate());
              this.setState({editModeDisabled: true});
              this.props.handleClose();
            }}
          >
            { this.state.editModeDisabled ? t("edit") : t("save") }
          </Button>
          <Button 
            onClick={() => {
              this.setState({editModeDisabled: true});
              this.props.handleClose();
            }} 
            color="primary"
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(AccountSettingsDialog);
