import React, { Component } from "react";
import {
  getUserById,
  fetchAlbums,
  sendCreateAlbum,
  removeAlbum,
  shareAlbum,
  renameAlbum,
  sortAlbum,
  createAlbumDownloadUrl
} from "../../api.js";
import PanoramaIcon from "@material-ui/icons/Panorama";
import GroupIcon from "@material-ui/icons/Group";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import "../../style.css";
import NewAlbumDialog from "./NewAlbumDialog.jsx";
import RenameDialog from "./RenameDialog.jsx";
import SharingDialog from "./SharingDialog.jsx";
import Pages from "../../components/Pages.jsx";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LanguageDialog from "../../components/LanguageDialog";
import { withTranslation, I18nextProvider } from "react-i18next";
import i18n from "../../config/i18n";
import AccountSettingsDialog from "../../components/AccountSettingsDialog.jsx";
import AccountDeletionDialog from "../../components/AccountDeletionDialog.jsx";
import AlbumDeletionDialog from "../../components/AlbumDeletionDialog.jsx";

const ROW_SIZE = 3;
const ALBUMS_PER_PAGE = 9;

class AlbumMain extends Component {
  state = {
    userId: -1,
    firstName: "",
    lastName: "",
    albums: [],
    pages: [],
    currentPage: 1,
    fromId: -1,
    newAlbumDialogOpen: false,
    languageDialogOpen: false,
    accountSettingsDialogOpen: false,
    screenWidth: -1,
    anchorEl: null,
    moreOpen: false,
    isMenuOpen: false,
    selectedAlbum: null,
    selectedAlbumId: -1,
    selectedAlbumTitle: "",
    renameDialogOpen: false,
    isSharingEnabled: false,
    sharingDialigOpen: false,
    accountDeletionDialogOpen: false,
    albumDeletionDialogOpen: false,
    alreadySharedUsers: [],
    userInfo: {},
  };

  componentDidMount() {
    const userId = Number(localStorage.getItem("id"));

    this.setState({ screenWidth: window.innerWidth });

    getUserById(userId, (r) => {
      this.setState({
        userId: userId,
        firstName: r.firstName,
        lastName: r.lastName,
        userInfo: r,
      });
      this.getAlbums();
    });
  }

  refreshUserInfo() {
    const userId = Number(localStorage.getItem("id"));
    localStorage.removeItem("cache:userbyid:" + userId);

    getUserById(userId, (r) => {
      this.setState({
        userInfo: r,
      });
    });
  }

  countRows() {
    const size = 3;
    var arr = [];
    for (var i = 0; i < size; i++) {
      arr.push(0);
    }
    return arr;
  }

  getAlbums() {
    const userId = Number(localStorage.getItem("id"));
    fetchAlbums(userId, (r) => {
      this.setState({ albums: r });
      this.updatePageButtons(r);
    });
  }

  createAlbum(title) {
    sendCreateAlbum(this.state.userId, { name: title }, () => this.getAlbums());
  }

  deleteAlbum(id) {
    removeAlbum(this.state.userId, id, () => this.getAlbums());
  }

  updatePageButtons(albums) {
    var newPages = [];
    var count = 1;
    for (var i = 0; i < albums.length; i++) {
      if (i % ALBUMS_PER_PAGE === 0) {
        newPages.push(count);
        count += 1;
      }
    }
    this.setState({ pages: newPages });
  }

  handleNewAlbumClick() {
    this.setState({ newAlbumDialogOpen: true });
  }

  handleCloseAlbumDialog() {
    this.setState({ newAlbumDialogOpen: false });
  }

  handleCreateNewAlbumDialog(title) {
    this.createAlbum(title);
    this.setState({ newAlbumDialogOpen: false });
  }

  handleOpenAlbum(id, name) {
    window.location.href = "/album/" + id;
  }

  getDesktopIconStyle() {
    return { width: "8em", height: "8em", marginBottom: "-1em" };
  }

  getMobileIconStyle() {
    return { width: "4em", height: "4em" };
  }

  getDesktopSettingsDropdownIconStyle() {
    return { width: "1.2em", paddingBottom: "0em", marginTop: "-0.6em" };
  }

  getMobileSettingsDropdownIconStyle() {
    return { width: "0.8em", paddingBottom: "0em", marginTop: "-0.4em" };
  }

  getDesktopMoreButtonStyle() {
    return {
      position: "absolute",
      padding: "1em 0em 0em 0em",
      marginLeft: "-1em",
    };
  }

  getMobileMoreButtonStyle() {
    return {
      position: "absolute",
      padding: "0.5em 0em 0em 0em",
      marginLeft: "-0.5em",
    };
  }

  getDesktopGroupIconStyle() {
    return {
      color: "white",
      position: "absolute",
      marginTop: "1.6em",
      marginLeft: "-2em",
    };
  }

  getMobileGroupIconStyle() {
    return {
      color: "white",
      position: "absolute",
      marginTop: "0.8em",
      marginLeft: "-1.4em",
    };
  }

  renderAlbumPreview(album) {
    return (
      <div key={album.id} className="albumPreviewOuter">
        <div
          key={"alPrev" + album.id}
          className="albumPreview"
          onClick={() => this.handleOpenAlbum(album.id, album.name)}
        >
          <PanoramaIcon
            key={"iconAlb" + album.id}
            style={
              this.state.screenWidth > 700
                ? this.getDesktopIconStyle()
                : this.getMobileIconStyle()
            }
          />
          {this.calculateSharingEnabled(album.id) ? (
            <></>
          ) : (
            <GroupIcon
              style={
                this.state.screenWidth > 700
                  ? this.getDesktopGroupIconStyle()
                  : this.getMobileGroupIconStyle()
              }
            />
          )}
          <div key={"alPrevName" + album.id}>{album.name}</div>
        </div>
        <IconButton
          key={"iconMore" + album.id}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          style={
            this.state.screenWidth > 700
              ? this.getDesktopMoreButtonStyle()
              : this.getMobileMoreButtonStyle()
          }
          onClick={(e) => this.handleMoreClick(e, album)}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </div>
    );
  }

  renderAlbumRow(rowNum) {
    const { t } = this.props;

    if (this.state.fromId < 0 && rowNum === 0) {
      return (
        <div key={rowNum} className="albumRow">
          <div
            key={"newAlbum"}
            className="albumPreview"
            onClick={() => this.handleNewAlbumClick()}
          >
            <CreateNewFolderIcon
              key={"iconNew"}
              style={
                this.state.screenWidth > 700
                  ? this.getDesktopIconStyle()
                  : this.getMobileIconStyle()
              }
            />
            <div key={"newAlbumTxt"}>{t("create_album")}</div>
          </div>
          {this.state.albums
            .slice(0, ROW_SIZE - 1)
            .map((album) => this.renderAlbumPreview(album))}
        </div>
      );
    } else {
      return (
        <div key={rowNum} className="albumRow">
          {this.state.albums
            .slice(
              this.state.fromId + rowNum * ROW_SIZE,
              this.state.fromId + rowNum * ROW_SIZE + ROW_SIZE
            )
            .map((album) => this.renderAlbumPreview(album))}
        </div>
      );
    }
  }

  renderAlbumPallete() {
    return (
      <div className="albumPallete">
        {this.countRows().map((val, i) => {
          return this.renderAlbumRow(i);
        })}
      </div>
    );
  }

  calculateSharingEnabled(albumId) {
    const userId = this.state.userId;
    return (
      this.state.albums.filter((a) => a.id === albumId && a.userId === userId)
        .length > 0
    );
  }

  handleMoreClick(e, a) {
    this.setState({
      anchorEl: e.currentTarget,
      moreOpen: true,
      selectedAlbum: a,
      selectedAlbumId: a.id,
      selectedAlbumTitle: a.name,
      isSharingEnabled: this.calculateSharingEnabled(a.id),
    });
  }

  handleMoreClose() {
    this.setState({ anchorEl: null, moreOpen: false });
  }

  handleDelete() {
    this.deleteAlbum(this.state.selectedAlbumId);
    this.handleMoreClose();
    this.setState({albumDeletionDialogOpen: false});
  }

  handleRename() {
    this.setState({ renameDialogOpen: true });
    this.handleMoreClose();
  }

  handleShare() {
    this.loadSharedUsers();
    this.setState({ sharingDialigOpen: true });
    this.handleMoreClose();
  }

  handleSort() {
    sortAlbum(this.state.userId, this.state.selectedAlbumId, !this.state.selectedAlbum.isChronologicalOrder, () => {
      this.getAlbums();
      this.handleMoreClose();
    });
  }

  handleDownload() {
    const downloadUrl = createAlbumDownloadUrl(this.state.userId, this.state.selectedAlbumId);
    this.handleMoreClose();
    window.location.replace(downloadUrl);
  }

  async loadSharedUsers() {
    const ids = this.state.selectedAlbum.sharedUsers;
    this.setState({ alreadySharedUsers: [] });
    this.resolveName(ids, []);
  }

  resolveName(ids, resultArray) {
    if (ids.length === 0) {
      this.setState({ alreadySharedUsers: resultArray });
      return;
    }

    const id = ids[0];

    getUserById(id, (r) => {
      const err = r.error;
      if (err != null) {
        console.log(err);
        return;
      }
      const toAdd = r.firstName + " " + r.lastName;
      resultArray.push(toAdd);
      this.resolveName(Array.prototype.slice.call(ids, 1), resultArray);
    });
  }

  doShare(idToShare) {
    shareAlbum(
      this.state.userId,
      this.state.selectedAlbumId,
      [idToShare],
      () => {
        this.getAlbums();
      }
    );
  }

  doRename(newTitle) {
    renameAlbum(this.state.userId, this.state.selectedAlbumId, newTitle, () => {
      this.getAlbums();
    });
  }

  handleMenuClick(e) {
    this.setState({ isMenuOpen: true, anchorEl: e.currentTarget });
  }

  handleMenuClose() {
    this.setState({ isMenuOpen: false });
  }

  handleLangChangeItemClick() {
    this.setState({ isMenuOpen: false, languageDialogOpen: true });
  }

  handleAccountSettingsItemClick() {
    this.setState({ isMenuOpen: false, accountSettingsDialogOpen: true });
  }

  handleDeleteAccountItemClick() {
    this.setState({ isMenuOpen: false, accountDeletionDialogOpen: true });
  }

  handleLogout() {
    this.setState( { isMenuOpen: false });
    localStorage.clear();
    window.location.href = "/login";
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="logo">
          Lifecapsule
          <div className="signature">
            by{" "}
            <a href="https://leskor.com" alt="Ruslan Lesko">
              Ruslan Lesko
            </a>
          </div>
        </div>
        <div
          className="content"
          style={this.state.screenWidth < 700 ? { margin: "0.2em" } : {}}
        >
          <div className="userInfo">
            {this.state.firstName} {this.state.lastName}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              style={
                this.state.screenWidth > 700
                  ? this.getDesktopSettingsDropdownIconStyle()
                  : this.getMobileSettingsDropdownIconStyle()
              }
              onClick={(e) => this.handleMenuClick(e)}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </div>
          {this.renderAlbumPallete()}
          <Pages
            totalItems={this.state.albums.length + 1}
            itemsPerPage={ALBUMS_PER_PAGE}
            currentPage={this.state.currentPage}
            onChange={(newPage) => {
              const newFrom = ALBUMS_PER_PAGE * (newPage - 1) - 1;
              this.setState({ currentPage: newPage, fromId: newFrom });
            }}
          />

          <I18nextProvider i18n={i18n}>
            <NewAlbumDialog
              open={this.state.newAlbumDialogOpen}
              handleClose={() => this.handleCloseAlbumDialog()}
              handleCreate={(title) => this.handleCreateNewAlbumDialog(title)}
            />
          </I18nextProvider>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.moreOpen}
            onClose={() => this.handleMoreClose()}
            PaperProps={{
              style: {
                width: "28ch",
                marginTop: "2.8em",
                marginLeft: "1em",
              },
            }}
          >
            <MenuItem
              key={1}
              onClick={() => this.handleRename()}
              disabled={!this.state.isSharingEnabled}
            >
              {t("rename")}
            </MenuItem>
            <MenuItem
              key={2}
              onClick={() => this.handleShare()}
              disabled={!this.state.isSharingEnabled}
            >
              {t("share")}
            </MenuItem>
            <MenuItem
              key={3}
              onClick={() => this.setState({albumDeletionDialogOpen: true})}
              disabled={!this.state.isSharingEnabled}
            >
              {t("delete")}
            </MenuItem>
            <MenuItem
              key={4}
              onClick={() => this.handleDownload()}
            >
              {t("download")}
            </MenuItem>
            <MenuItem
              key={5}
              onClick={() => this.handleSort()}
              disabled={!this.state.isSharingEnabled}
            >
              { this.state.selectedAlbum === null ? "" : this.state.selectedAlbum.isChronologicalOrder ? t("sort_newer_first") : t("sort_oldest_first") }
            </MenuItem>
          </Menu>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.isMenuOpen}
            onClose={() => this.handleMenuClose()}
            PaperProps={{
              style: {
                width: "20ch",
                marginTop: "2.8em",
                marginLeft: "1em",
              },
            }}
          >
            <MenuItem key={0} onClick={() => this.handleLangChangeItemClick()}>
              {t("change_language")}
            </MenuItem>
            <MenuItem key={1} onClick={() => this.handleAccountSettingsItemClick()}>
              {t("account_settings")}
            </MenuItem>
            <MenuItem key={2} onClick={() => this.handleLogout()}>
              {t("logout")}
            </MenuItem>
            <MenuItem key={3} onClick={() => this.handleDeleteAccountItemClick()}>
              {t("delete_account")}
            </MenuItem>
          </Menu>
          <I18nextProvider i18n={i18n}>
            <RenameDialog
              open={this.state.renameDialogOpen}
              handleClose={() => this.setState({ renameDialogOpen: false })}
              handleRename={(newTitle) => {
                this.setState({ renameDialogOpen: false });
                this.doRename(newTitle);
              }}
            />
          </I18nextProvider>
          <I18nextProvider i18n={i18n}>
            <SharingDialog
              open={this.state.sharingDialigOpen}
              handleClose={() => this.setState({ sharingDialigOpen: false })}
              alreadySharedUsers={this.state.alreadySharedUsers}
              handleShare={(idToShare) => {
                if (idToShare > 0) {
                  this.doShare(idToShare);
                }
                this.setState({ sharingDialigOpen: false });
              }}
            />
          </I18nextProvider>
          <I18nextProvider i18n={i18n}>
            <LanguageDialog
              open={this.state.languageDialogOpen}
              handleClose={() => this.setState({ languageDialogOpen: false })}
            />
            <AccountSettingsDialog
              open={this.state.accountSettingsDialogOpen}
              userInfo={this.state.userInfo}
              onUpdate={() => this.refreshUserInfo()}
              handleClose={() => this.setState({ accountSettingsDialogOpen: false })}
            />
            <AccountDeletionDialog
              open={this.state.accountDeletionDialogOpen}
              handleClose={() => this.setState({ accountDeletionDialogOpen: false })}
            />
            <AlbumDeletionDialog
              open={this.state.albumDeletionDialogOpen}
              handleClose={() => this.setState({ albumDeletionDialogOpen: false })}
              handleDelete={() => this.handleDelete()}
            />
          </I18nextProvider>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AlbumMain);
