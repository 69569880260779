import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import "../style.css";
import { withTranslation } from "react-i18next";

class AlbumDeletionDialog extends Component {
  render() {
    const { t } = this.props;

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose}>
        <DialogTitle>{t("confirm_delete_album")}</DialogTitle>
        <DialogActions>
          <Button 
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() => this.props.handleDelete()}
          >
            {t("delete")}
          </Button>
          <Button onClick={this.props.handleClose} color="primary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(AlbumDeletionDialog);
