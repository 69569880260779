import React from "react";
import "../../style.css";
import { Dialog } from "@material-ui/core";
import { useSwipeable } from "react-swipeable";

function PicDialog(props) {
  const swiped = (e) => {
    if (e.deltaX > 10) {
      props.swipe(true);
    }
    if (e.deltaX < -10) {
      props.swipe(false);
    }
  };

  const handlers = useSwipeable({
    onSwiped: swiped
  });

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={true}
      scroll={"body"}
      onClick={() => props.handleClose()}
      aria-labelledby=""
    >
      <div {...handlers} className="imageFull">
        <span className="helper"></span>
        <img src={props.src} alt="" />
      </div>
    </Dialog>
  );
}

export default PicDialog;
