import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import "../style.css";
import i18n from "../config/i18n.js";
import { withTranslation } from "react-i18next";

class LanguageDialog extends Component {
  onSelection(lang) {
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose}>
        <DialogTitle>{t("language")}</DialogTitle>
        <DialogContent>
          <div className="button">
            <img src="/en.png" alt="Great Britan Flag" />
            <Button
              onClick={() => {
                this.onSelection("en");
                this.props.handleClose();
              }}
              color="primary"
            >
              English
            </Button>
          </div>
          <div className="button">
            <img src="/uk.png" alt="Ukraine Flag" />
            <Button
              onClick={() => {
                this.onSelection("uk");
                this.props.handleClose();
              }}
              color="primary"
            >
              Українська
            </Button>
          </div>
          <div className="button">
            <img src="/ru.png" alt="Russia Flag" />
            <Button
              onClick={() => {
                this.onSelection("ru");
                this.props.handleClose();
              }}
              color="primary"
            >
              Русский
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(LanguageDialog);
