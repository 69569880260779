import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locale/en";
import uk from "../locale/uk";
import ru from "../locale/ru";

const selectedLang = localStorage.getItem("language");
const lang =
  selectedLang == null
    ? window.navigator.language.substring(0, 2)
    : selectedLang;

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    uk: {
      translation: uk,
    },
    ru: {
      translation: ru,
    },
  },
  lng: lang,
  fallbackLng: "en",
  debug: true,
  ns: ["translation"],
  defaultNS: "translation",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    nsMode: "default",
  },
});

export default i18n;
