import { fetchImage } from "./api.js";

function redirectToLogin() {
  window.location.replace("/login");
}

const urlCreator = window.URL || window.webkitURL;

function loadPics(albumIds) {
  albumIds.forEach((id) => {
    const key = "url" + id.pictureId;
    if (localStorage.getItem(key) === null) {
      localStorage.setItem(key, "");
    }
  });
  albumIds.forEach((id) => {
    if (localStorage.getItem("url" + id.pictureId) === "") {
      loadImage(id.userId, id.pictureId);
    }
  });
}

function dropPics(pics) {
  pics.forEach((p) => {
    const url = "url" + p.pictureId;
    const src = localStorage.getItem(url);
    urlCreator.revokeObjectURL(src);
    localStorage.removeItem(url);
  });
}

function loadImage(userId, picId) {
  fetchImage(userId, picId, (data) => {
    const imageUrl = urlCreator.createObjectURL(data);
    localStorage.setItem("url" + picId, imageUrl);
  });
}

export { redirectToLogin, loadPics, dropPics };
