import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import PicsGallery from "./PicsGallery";
import Pages from "../../components/Pages.jsx";
import {
  getUserById,
  fetchAlbumContents,
  fetchAlbums,
  uploadPicture,
  deletePicture,
  rotateImage,
} from "../../api.js";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Header from "../Header.jsx";
import { withTranslation } from "react-i18next";
import "../../style.css";

class PicsHome extends Component {
  PICS_PER_GALLERY = 18;

  state = {
    userId: -1,
    firstName: "",
    lasetName: "",
    pages: [],
    currentPage: 1,
    albumPics: [],
    albumId: -1,
    albumTitle: "",
    fromId: 0,
    uploadMsg: "",
    msgColor: "",
    filesSelected: 0,
    isMobile: false,
    progress: -1,
    userInfo: {},
  };

  componentDidMount() {
    const userId = Number(localStorage.getItem("id"));

    getUserById(userId, (r) => {
      this.setState({
        userId: userId,
        firstName: r.firstName,
        lastName: r.lastName,
        albumId: this.props.albumId,
        isMobile: window.innerWidth < 700,
        userInfo: r,
      });
      this.loadAlbumTitle();
      this.loadPics();
    });
  }

  refreshUserInfo() {
    const userId = Number(localStorage.getItem("id"));
    localStorage.removeItem("cache:userbyid:" + userId);

    getUserById(userId, (r) => {
      this.setState({
        userInfo: r,
      });
    });
  }

  loadPics() {
    const userId = localStorage.getItem("id");
    const albumId = this.props.albumId;

    fetchAlbumContents(userId, albumId, (r) => {
      this.setState({ albumPics: r });
      this.updatePageButtons(r);
    });
  }

  loadAlbumTitle() {
    const userId = localStorage.getItem("id");

    fetchAlbums(userId, (r) => {
      const id = this.props.albumId;
      const titleArr = r
        .filter((album) => album.id.toString() === id.toString())
        .map((album) => album.name);
      if (titleArr.length > 0) {
        this.setState({ albumTitle: titleArr[0] });
        document.title = titleArr[0];
      }
    });
  }

  updatePageButtons(pics) {
    var newPages = [];
    var count = 1;
    for (var i = 0; i < pics.length; i++) {
      if (i % this.PICS_PER_GALLERY === 0) {
        newPages.push(count);
        count += 1;
      }
    }
    this.setState({ pages: newPages });
  }

  async inputChanged() {
    this.setState({ uploadMsg: "", msgColor: "" });
    const input = document.querySelector('input[type="file"]');
    this.setState({ filesSelected: input.files.length });
  }

  async uploadPics() {
    this.setState({ uploadMsg: "", progress: 0 });
    const input = document.querySelector('input[type="file"]');
    this.doUpload(input.files);
  }

  doUpload(selectedPics) {
    const { t } = this.props;

    const total = this.state.filesSelected;
    const left = selectedPics.length;
    const newProgres = ((total - left) * 100) / total;

    this.setState({ progress: newProgres });

    uploadPicture(
      this.state.userId,
      this.state.albumId,
      selectedPics[0],
      () => {
        if (selectedPics.length > 1) {
          this.doUpload(Array.prototype.slice.call(selectedPics, 1));
        } else {
          this.setState({
            uploadMsg: t("upload_succeeded"),
            msgColor: "",
            progress: 100,
            filesSelected: 0,
          });
          this.loadPics();
          document.querySelector('input[type="file"]').value = "";
        }
      },
      (e) => {
        const newMsg = e.code === 1 ? t("storage_limit_exceeded") : e.message;
        this.setState({ 
          uploadMsg: newMsg,
          msgColor: "#c93640"
        });
      }
    );
  }

  onDelete(pictureId, userId) {
    deletePicture(userId, pictureId, () => {
      this.loadPics();
    });
  }

  onRotate(pictureId, userId) {
    rotateImage(userId, pictureId, () => {
      this.setState({ albumPics: [] });
      this.loadPics();
    });
  }

  backToAlbums() {
    window.location.href = "/";
  }

  formatFullName() {
    const first = this.state.firstName;
    const last = this.state.isMobile
      ? this.state.lastName.substring(0, 1) + "."
      : this.state.lastName;
    return first + " " + last;
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Header
          name={this.formatFullName()}
          userInfo={this.state.userInfo}
          onUpdate={() => this.refreshUserInfo()}
          albumTitle={this.state.isMobile ? null : this.state.albumTitle}
        />
        {this.state.isMobile ? (
          <div className="albumTitle">{this.state.albumTitle}</div>
        ) : (
          <div></div>
        )}
        <div className="content">
          <div className="button" style={{ float: "right", marginTop: "0em" }}>
            <Button
              variant={this.state.filesSelected > 0 ? "contained" : "outlined"}
              color="primary"
              style={this.state.filesSelected > 0 ? { color: "white" } : {}}
              component="label"
              onClick={() =>
                this.state.filesSelected > 0 ? this.uploadPics() : {}
              }
            >
              {this.state.filesSelected > 0
                ? t("confirm_upload_of") +
                  " " +
                  this.state.filesSelected +
                  " " +
                  (this.state.filesSelected > 1 ? t("images") : t("image"))
                : t("upload_images")}
              <input
                type="file"
                id="fileUploaderBtn"
                multiple
                style={{ display: "none" }}
                onChange={(e) => this.inputChanged()}
                disabled={this.state.filesSelected > 0}
              />
            </Button>
            {this.state.filesSelected > 0 ? (
              this.state.isMobile ? (
                <IconButton
                  color="primary"
                  onClick={() => this.setState({ filesSelected: 0 })}
                >
                  <CancelIcon
                    style={{
                      width: "0.6em",
                      height: "1em",
                      marginTop: "0.4em",
                    }}
                  />
                </IconButton>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: "1em" }}
                  onClick={() => {
                    document.getElementById("fileUploaderBtn").value = "";
                    this.setState({ filesSelected: 0 });
                  }}
                >
                  {t("cancel")}
                </Button>
              )
            ) : (
              <div style={{ display: "inline" }}></div>
            )}
          </div>
          {this.state.isMobile && this.state.filesSelected > 0 ? (
            <></>
          ) : (
            <div className="button">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.backToAlbums}
              >
                <ArrowBackIosIcon />
                {t("albums")}
              </Button>
            </div>
          )}
          {this.state.progress < 0 ? (
            <></>
          ) : (
            <LinearProgress
              variant="determinate"
              value={this.state.progress}
              style={{ marginTop: "0.6em", overflow: "initial" }}
            />
          )}
          <div 
            className="uploadMsg" 
            style={this.state.msgColor === "" ? {} : { color: this.state.msgColor }}
          >
            {this.state.uploadMsg}
          </div>
          <PicsGallery
            userId={this.state.userId}
            albumPics={this.state.albumPics}
            fromId={this.state.fromId}
            onDelete={(pid, uid) => this.onDelete(pid, uid)}
            onRotate={(pid, uid) => this.onRotate(pid, uid)}
          />
          <Pages
            totalItems={this.state.albumPics.length}
            itemsPerPage={this.PICS_PER_GALLERY}
            currentPage={this.state.currentPage}
            onChange={(newPage) => {
              const newFrom = this.PICS_PER_GALLERY * (newPage - 1);
              this.setState({ currentPage: newPage, fromId: newFrom });
            }}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PicsHome);
