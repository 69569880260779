import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "../style.css";

class Pages extends Component {
  MAX_AMOUNT = 5;

  state = {
    isMobile: false,
  };

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth < 700 });
  }

  calculatePageAmount(total, perPage) {
    var result = 0;
    for (var i = 0; i < total; i++) {
      if (i % perPage === 0) {
        result += 1;
      }
    }

    return result;
  }

  createPageArray() {
    const size = this.calculatePageAmount(
      this.props.totalItems,
      this.props.itemsPerPage
    );

    const result = [];
    const current = this.props.currentPage;

    if (size > this.MAX_AMOUNT) {
      for (var i = 0; i < size; i++) {
        if (i === 0 || i === size - 1 || Math.abs(i - (current - 1)) < 2) {
          if (
            !this.state.isMobile &&
            i !== 0 &&
            result[result.length - 1].num !== i
          ) {
            if (
              result[result.length - 2] !== undefined &&
              result[result.length - 2].num === i - 1
            ) {
              result.push({ num: i - 1, display: i - 1 });
            } else {
              result.push({ num: i, display: "..." });
            }
          }

          result.push({ num: i + 1, display: i + 1 });
        }
      }
    } else {
      for (var j = 0; j < size; j++) {
        result.push({ num: j + 1, display: j + 1 });
      }
    }

    return result;
  }

  renderButton(total, i, isCurrent, onChange) {
    return (
      <div key={i.num} className="pageButton">
        <Button
          variant="outlined"
          color="primary"
          disabled={isCurrent}
          onClick={() => {
            onChange(i.num);
          }}
        >
          {i.display}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="pages">
        {this.createPageArray().map((item, i) =>
          this.renderButton(
            this.props.totalItems,
            item,
            this.props.currentPage === item.num,
            this.props.onChange
          )
        )}
      </div>
    );
  }
}

export default Pages;
