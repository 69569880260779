import React, { Component } from "react";
import PicPreview from "./PicPreview";
import PicDialog from "./PicDialog";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withTranslation } from "react-i18next";
import { loadPics, dropPics } from "../../utils.js";
import { createDownloadUrl } from "../../api";
import PictureDeletionDialog from "../../components/PictureDeletionDialog";

class PicsGallery extends Component {
  state = {
    openedPicId: -1,
    openedUserId: -1,
    src: "",
    albumPics: [],
    width: -1,
    height: -1,
    anchorEl: null,
    moreOpen: false,
    selectedId: -1,
    selectedUser: -1,
    downloadCode: "",
    editsDisabled: true,
    userId: -1,
    pictureDeletionDialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      albumPics: props.albumPics,
      openedPicId: -1,
      openedUserId: -1,
      src: "",
    };
  }

  componentDidMount() {
    const userId = Number(localStorage.getItem("id"));
    this.setState({ userId: userId });
    this.dropUnusedPics();
    this.setState({ width: window.innerWidth, height: window.screen.height });

    document.addEventListener('fullscreenchange', _ => {
      if (!document.fullscreenElement) {
        this.handleClose(); 
      }
    });
  }

  handleClose() {
    this.setState({ openedPicId: -1, openedUserId: -1 });
    this.dropUnusedPics();
    this.closeFullscreen();
  }

  handleClick(userId, picId) {
    if (this.state.openedPicId === -1) {
      var newSrc = localStorage.getItem("url" + picId);
      this.openFullscreen();
      this.setState({ openedPicId: picId, openedUserId: userId, src: newSrc });
      this.updatePicData(userId, picId);
    } else {
      this.handleClose();
    }
  }

  openFullscreen() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch(_ => {});
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }

  async dropUnusedPics() {
    const idxFrom = this.props.fromId;
    const idxTo = idxFrom + 18;
    const ids = this.props.albumPics;

    const leftArr = ids.slice(0, idxFrom);
    var finalArr = leftArr;
    if (ids.length > idxTo) {
      finalArr = leftArr.concat(ids.slice(idxTo, ids.length));
    }

    if (finalArr.length > 0) {
      dropPics(finalArr);
    }
  }

  updatePicData(userId, picId) {
    const idsToLoad = this.getNearbyIds(userId, picId);
    this.doLoadPics(idsToLoad);
  }

  getPicForIndex(index, fromId, albumIds) {
    const idx = index + fromId;

    if (albumIds === null || albumIds.length < idx + 1) {
      return "";
    }

    var uId = albumIds[idx].userId;
    var pId = albumIds[idx].pictureId;

    return (
      <a href={"#" + pId} onClick={() => this.handleClick(uId, pId)}>
        <PicPreview
          key={idx}
          userId={this.state.userId}
          picId={pId}
          isMobile={this.state.width < 600}
        />
      </a>
    );
  }

  getNearbyIds(currentUser, currentId) {
    const radius = 5;

    const ids = this.props.albumPics;
    const currentIdx = ids.findIndex(
      (i) => i.userId === currentUser && i.pictureId === currentId
    );

    var left = 0;
    var right = ids.length - 1;

    if (currentIdx - radius > 0) {
      left = currentIdx - radius;
    }

    if (currentIdx + radius < ids.length - 1) {
      right = currentIdx + radius;
    }

    return ids.slice(left, right + 1);
  }

  async doLoadPics(ids) {
    const newIds = ids.map((v) => {
      return { userId: this.state.userId, pictureId: v.pictureId };
    });
    loadPics(newIds);
  }

  getKeyForIndex(index, fromId, albumIds) {
    const idx = index + fromId;

    if (albumIds === null || albumIds.length < idx + 1) {
      return 1000 - index;
    }

    return albumIds[idx].pictureId;
  }

  handleKeyPress(event, id) {
    if (id <= 0) {
      return;
    }
    const code = event.keyCode;
    if (code === 37) {
      this.changeImage(true);
    } else if (code === 39) {
      this.changeImage(false);
    }
  }

  changeImage(isLeft) {
    var newPicId = null;
    if (isLeft) {
      newPicId = this.getSideId(true);
    } else {
      newPicId = this.getSideId(false);
    }

    if (newPicId !== null) {
      var newSrc = localStorage.getItem("url" + newPicId.pictureId);
      this.setState({
        openedUserId: newPicId.userId,
        openedPicId: newPicId.pictureId,
        src: newSrc,
      });
      this.updatePicData(newPicId.userId, newPicId.pictureId);
    }
  }

  getSideId(isLeft) {
    const targetPicId = this.state.openedPicId;
    const targetUserId = this.state.openedUserId;
    const ids = this.props.albumPics;

    var left = null;
    var right = null;

    for (var i = 0; i < ids.length; i++) {
      const val = ids[i];

      if (i > 0) {
        left = ids[i - 1];
      }

      if (i < ids.length - 1) {
        right = ids[i + 1];
      } else {
        right = null;
      }

      if (targetPicId === val.pictureId && targetUserId === val.userId) {
        if (isLeft) {
          return left;
        } else {
          return right;
        }
      }
    }

    return null;
  }

  countRows(w) {
    var size = 3;
    if (w < 1200) {
      size = 6;
    }
    if (w < 600) {
      size = 9;
    }
    var arr = [];
    for (var i = 0; i < size; i++) {
      arr.push(0);
    }
    return arr;
  }

  countIdsForRow(total, row, size) {
    const fromIdx = row * size;
    const toIdx = fromIdx + size;
    var arr = [];
    for (var i = fromIdx; i < toIdx; i++) {
      arr.push(i);
    }
    return arr;
  }

  handleMoreClick(event, i) {
    const idx = i + this.props.fromId;
    const pid = this.props.albumPics[idx].pictureId;
    const uid = this.props.albumPics[idx].userId;
    const downloadCode = this.props.albumPics[idx].downloadCode;
    this.setState({
      anchorEl: event.currentTarget,
      moreOpen: true,
      selectedId: pid,
      selectedUser: uid,
      downloadCode: downloadCode,
      editsDisabled: !this.isEditable(i),
    });
  }

  isEditable(i) {
    const idx = i + this.props.fromId;
    const uid = this.props.albumPics[idx].userId;
    return uid === this.state.userId;
  }

  handleMoreClose() {
    this.setState({ anchorEl: null, moreOpen: false });
  }

  handleDelete() {
    const toDelete = this.state.selectedId;
    const usr = this.state.selectedUser;
    this.props.onDelete(toDelete, usr);
    this.handleMoreClose();
    this.setState({ pictureDeletionDialogOpen: false });
  }

  handleDownload() {
    const idToDownload = this.state.selectedId;
    const url = createDownloadUrl(this.state.userId, idToDownload);
    this.handleMoreClose();
    window.location.replace(url);
  }

  handleRotate() {
    this.props.onRotate(this.state.selectedId, this.state.selectedUser);
    this.handleMoreClose();
  }

  hasPicture(index) {
    const idx = index + this.props.fromId;
    return this.props.albumPics.length > idx;
  }

  renderPicsRow(rowNum, w) {
    var size = 6;
    if (w < 1200) {
      size = 3;
    }
    if (w < 600) {
      size = 2;
    }
    return (
      <tr key={rowNum}>
        {this.countIdsForRow(18, rowNum, size).map((i) => {
          return (
            <td
              key={this.getKeyForIndex(
                i,
                this.props.fromId,
                this.props.albumPics
              )}
            >
              {this.getPicForIndex(i, this.props.fromId, this.props.albumPics)}
              {this.hasPicture(i) ? (
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  style={{ position: "absolute", padding: "0em" }}
                  onClick={(e) => this.handleMoreClick(e, i)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </td>
          );
        })}
      </tr>
    );
  }

  renderPicsPallete(w, h) {
    return (
      <table>
        <tbody>
          {this.countRows(w).map((val, i) => this.renderPicsRow(i, w))}
        </tbody>
      </table>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div onKeyDown={(e) => this.handleKeyPress(e, this.state.openedPicId)}>
        {this.renderPicsPallete(this.state.width, this.state.height)}
        <PicDialog
          handleClose={() => this.handleClose()}
          open={this.state.openedPicId > -1}
          src={this.state.src}
          swipe={(isLeft) => this.changeImage(isLeft)}
        />
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.moreOpen ? this.state.moreOpen : false}
          onClose={() => this.handleMoreClose()}
          PaperProps={{
            style: {
              width: "20ch",
              marginTop: "2.8em",
              marginLeft: "1em",
            },
          }}
        >
          <MenuItem
            key={0}
            onClick={() => this.handleRotate()}
            disabled={this.state.editsDisabled}
          >
            {t("rotate")}
          </MenuItem>
          <MenuItem
            key={1}
            onClick={() => this.setState({ pictureDeletionDialogOpen: true })}
            disabled={this.state.editsDisabled}
          >
            {t("delete")}
          </MenuItem>
          <MenuItem
            key={2}
            onClick={() => this.handleDownload()}
          >
            {t("download")}
          </MenuItem>
        </Menu>
        <PictureDeletionDialog
          open={this.state.pictureDeletionDialogOpen == null ? false : this.state.pictureDeletionDialogOpen}
          handleClose={() => this.setState({ pictureDeletionDialogOpen: false })}
          handleDelete={() => this.handleDelete()}
        />
      </div>
    );
  }
}

export default withTranslation()(PicsGallery);
