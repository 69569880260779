import React, { Component } from "react";
import { fetchImage } from "../../api.js";

class PicPreview extends Component {
  urlCreator = window.URL || window.webkitURL;

  state = {
    userId: -1,
    picId: -1,
    src: "",
  };

  constructor(props) {
    super(props);
    this.state = { userId: props.userId, picId: props.picId };
    this.loadImage(props.userId, props.picId);
  }

  componentWillUnmount() {
    this.urlCreator.revokeObjectURL(this.state.src);
    localStorage.removeItem("url" + this.state.picId);
  }

  loadImage(userId, picId) {
    fetchImage(userId, picId, (data) => {
      const imageUrl = this.urlCreator.createObjectURL(data);
      this.setState({ src: imageUrl });
      localStorage.setItem("url" + picId, imageUrl);
    });
  }

  render() {
    return (
      <div
        className="picPreview"
        style={{ height: this.props.isMobile ? "auto" : "10em" }}
      >
        <img src={this.state.src} alt="" />
      </div>
    );
  }
}

export default PicPreview;
