import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { getUserByUsername } from "../../api.js";
import { withTranslation } from "react-i18next";

class SharingDialog extends Component {
  state = {
    username: "",
    idToShare: -1,
    userToShare: null,
    sharedUserMsg: "",
    isFound: false,
    screenWidth: -1,
  };

  componentDidMount() {
    this.setState({ screenWidth: window.innerWidth });
  }

  clearState() {
    this.setState({ username: "", isFound: false, sharedUserMsg: "" });
  }

  newInput(newUsername) {
    this.setState({ username: newUsername });
  }

  getDesktopStyle() {
    return { marginTop: "1.8em", marginLeft: "0.6em", color: "white" };
  }

  getMobileStyle() {
    return {
      marginTop: "1em",
      marginLeft: "0.6em",
      marginBotton: "2em",
      color: "white",
    };
  }

  checkUsername() {
    const { t } = this.props;

    getUserByUsername(this.state.username, (r) => {
      const err = r.error;
      if (err != null) {
        console.log(err);
        this.setState({ sharedUserMsg: t("user_not_found"), isFound: false });
        return;
      }
      this.setState({
        userToShare: r,
        idToShare: r.id,
        isFound: true,
        sharedUserMsg: t("found_user") + ": " + r.firstName + " " + r.lastName,
      });
    });
  }

  renderSharedUser(usr) {
    return <li key={usr}>{usr}</li>;
  }

  renderSharedUsers() {
    if (this.props.album === null) {
      return <ul className="sharedUsers"></ul>;
    }
    return (
      <ul className="sharedUsers">
        {this.props.alreadySharedUsers.map((usr) => this.renderSharedUser(usr))}
      </ul>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.clearState();
          this.props.handleClose();
        }}
      >
        <DialogTitle>{t("share_album")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("username")}
            margin="normal"
            variant="outlined"
            onChange={(e) => this.newInput(e.target.value)}
          />
          <Button
            onClick={() => this.checkUsername()}
            color="primary"
            variant="contained"
            disabled={this.state.username === ""}
            style={
              this.state.screenWidth > 700
                ? this.getDesktopStyle()
                : this.getMobileStyle()
            }
          >
            {t("check")}
          </Button>
          <div
            className="sharingUser"
            style={
              this.state.isFound
                ? { marginTop: "1em" }
                : { color: "red", marginTop: "1em" }
            }
          >
            {this.state.sharedUserMsg}
          </div>
          <h3>{t("shared_users")}</h3>
          {this.renderSharedUsers()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.clearState();
              this.props.handleShare(this.state.idToShare);
            }}
            color="primary"
            style={{ color: "white" }}
            variant="contained"
            disabled={!this.state.isFound}
          >
            {t("share")}
          </Button>
          <Button onClick={this.props.handleClose} color="primary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(SharingDialog);
