import React, { Component } from "react";
import { cyan } from "@material-ui/core/colors";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./style.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./screens/Login.jsx";
import Signup from "./screens/Signup.jsx";
import AlbumMain from "./screens/albumManagement/AlbumMain.jsx";
import PicsHome from "./screens/picView/PicsHome.jsx";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
import ForgotPassword from "./screens/ForgotPassword";
import RecoveryCode from "./screens/RecoveryCode";
import PasswordReset from "./screens/PasswordReset";

const theme = createTheme({
  palette: {
    primary: {
      main: cyan[600],
    },
    secondary: {
      main: cyan[50],
      type: "light",
    },
    type: "light",
  },
  typography: {
    useNextVariants: true,
    button: {
      color: "white",
    },
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <Switch>
              <Route
                exact
                path="/login"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <Login />
                  </I18nextProvider>
                )}
              />
              <Route
                exact
                path="/signup"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <Signup />
                  </I18nextProvider>
                )}
              />
              <Route
                exact
                path="/forgot_password"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <ForgotPassword />
                  </I18nextProvider>
                )}
              />
              <Route
                exact
                path="/recovery_code"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <RecoveryCode />
                  </I18nextProvider>
                )}
              />
              <Route
                exact
                path="/password_reset"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <PasswordReset />
                  </I18nextProvider>
                )}
              />
              <Route
                exact
                path="/"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <AlbumMain />
                  </I18nextProvider>
                )}
              />
              <Route
                exact
                path="/album/:id"
                render={({ match }) => (
                  <I18nextProvider i18n={i18n}>
                    <PicsHome albumId={match.params.id} />
                  </I18nextProvider>
                )}
              />
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
